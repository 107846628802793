import React, { useState } from "react";
import axios from "axios";
import "../../styles/style.scss";
import { Button } from "react-bootstrap";
import Info from "./InfoPNC";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfo,
  faPaperPlane,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import Helmet from "react-helmet";

const UploadPNC = () => {
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState("");
  const [modalShow, setModalShow] = useState(false);

  // Function to handle file selection
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!file) {
      setMessage("Please upload a file first!");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(
        "http://192.168.4.97:5002/upload-pnc",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setMessage(response.data.message);
    } catch (error) {
      console.error("Error uploading file:", error);
      setMessage(
        "Error uploading file: " +
          (error.response?.data?.error || error.message)
      );
    }
  };

  // Function to handle modal show
  const handleModalShow = () => setModalShow(true);

  // Function to handle modal close
  const handleModalClose = () => setModalShow(false);

  // Disable upload button if no file selected
  const isUploadDisabled = !file;

  return (
    <>
      <Helmet>
        <title>Upload PNC</title>
      </Helmet>
      <div className="upload-container">
        <div className="fieldContainer">
          <h2>
            Upload PNC File{" "}
            <Button
              onClick={handleModalShow}
              className="circleInfo"
              title="Read File Upload Guidelines"
            >
              <FontAwesomeIcon icon={faInfo} />
            </Button>
          </h2>
          <form onSubmit={handleSubmit} className="upload-form">
            <div className="file-input-container">
              <input
                type="file"
                id="file"
                onChange={handleFileChange}
                required
              />
              <label htmlFor="file" className="file-input-label">
                Choose File <FontAwesomeIcon icon={faUpload} />
              </label>
            </div>
            <button
              type="submit"
              className="upload-button"
              disabled={isUploadDisabled}
            >
              Upload <FontAwesomeIcon icon={faPaperPlane} />
            </button>
          </form>
          {message && <p className="upload-message">{message}</p>}
        </div>

        {/* Modal for uploading rules */}
        <Info show={modalShow} onHide={handleModalClose} />
      </div>
    </>
  );
};

export default UploadPNC;
