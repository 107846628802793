import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoffee, faUpload } from "@fortawesome/free-solid-svg-icons";





function HeaderNav({ isLoggedIn, handleLogout }) {
  const handleChangePassword = () => {
    if (!isLoggedIn) {
      alert("You are not authorized to change the password.");
      return;
    }
    alert("Change password functionality is not implemented yet.");
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
      <div className="container-fluid">
        <a className="navbar-brand" href="/">
          Algoquant
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <a className="nav-link" href="#action1">
                Link 1
              </a>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#link"
                id="navbarDropdownUpload"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Upload File
              </a>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdownUpload">
                <li>
                  <a className="dropdown-item" href="pnc">
                    Upload PNC <FontAwesomeIcon icon={faUpload} />
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="otr">
                    Upload OTR <FontAwesomeIcon icon={faUpload} />
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="gsm">
                    Upload GSM <FontAwesomeIcon icon={faUpload} />
                  </a>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <a className="dropdown-item" href="#others">
                    Others {" "}<FontAwesomeIcon icon={faCoffee} />
                  </a>
                </li>
              </ul>
            </li>
          </ul>
          <div className="d-flex" style={{marginRight: "10%"}}>
            <ul className="navbar-nav me-auto mb-3 mb-lg-0">
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#link"
                  id="navbarDropdownUser"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Devansh
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdownUser">
                  <li>
                    <a className="dropdown-item" href="#warning" onClick={handleChangePassword}>
                      Change Password
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="login" onClick={handleLogout}>
                      Logout
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default HeaderNav;
