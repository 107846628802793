export const fnoDefs = [
  // { headerName: "Broker", field: "BROKER", sortable: true, filter: true, chartDataType: 'category', width: 110 },
  { headerName: "Dealer ID", field: "DEALERID", sortable: true, filter: true, floatingFilter: true, width: 130 },
  { headerName: "Orders", field: "ORDERS", sortable: true, filter: true, floatingFilter: true, width: 150 },
  { headerName: "Trades", field: "TRADES", sortable: true, filter: true, floatingFilter: true, width: 150 },
  { headerName: "Ratio", field: "RATIO", sortable: true, filter: true, width: 150 },
  { headerName: "Date", field: "OTR_DATE", sortable: true, filter: true, width: 150 },
];

export const pncDefs = [
  // { headerName: "Member Code", field: "Mem Code", sortable: true, filter: true, width: 140 },
  { headerName: "Client Code", field: "Client Code", sortable: true, filter: true, floatingFilter: true, width: 140 },
  { headerName: "Client Name", field: "Client Name", sortable: true, filter: true, floatingFilter: true, width: 250 },
  { headerName: "PAN", field: "Pan", sortable: true, filter: true, width: 130 },
  { headerName: "Count (Prev Day)", field: "Count of instances till previous day", sortable: true, filter: true, width: 150 },
  { headerName: "Count (Today)", field: "Count of instances till today", sortable: true, filter: true, width: 150 },
  { headerName: "Segment", field: "Segment", sortable: true, filter: true, floatingFilter: true, width: 120 },
];

export const gsmDefs = [
  { headerName: "Day Date", field: "Day date", sortable: true, filter: true, floatingFilter: true, width: 150 },
  // { headerName: "Buy TM Code", field: "Buy TM Code", sortable: true, filter: true, floatingFilter: true, width: 150 },
  { headerName: "Buy Trading Member Name", field: "Buy Trading member name", sortable: true, filter: true, floatingFilter: true, width: 250 },
  { headerName: "Client Code", field: "Client Code", sortable: true, filter: true, floatingFilter: true, width: 140 },
  { headerName: "Client PAN", field: "Client PAN", sortable: true, filter: true, floatingFilter: true, width: 130 },
  { headerName: "Symbol", field: "Symbol", sortable: true, filter: true, floatingFilter: true, width: 120 },
  { headerName: "Company Name", field: "Company Name", sortable: true, filter: true, floatingFilter: true, width: 250 },
  { headerName: "Day Buy Value", field: "Day Buy Value", sortable: true, filter: true, floatingFilter: true, width: 150 },
  { headerName: "Surveillance Indicator", field: "Surveillance Indicator", sortable: true, filter: true, floatingFilter: true, width: 180 },
  { headerName: "Amount of ASD", field: "Amount of ASD", sortable: true, filter: true, floatingFilter: true, width: 150 },
];

