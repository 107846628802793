import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";

import Layout from "./components/Layout";
import Login from "./components/Login";
import UploadPNC from "./components/uploads/UploadPNC";
import UploadOTR from "./components/uploads/UploadOTR";
import UploadGSM from "./components/uploads/UploadGSM";
// import GridExample from "./components/Testings/Table";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* Public routes */}
        <Route path="/login" element={<Login />} />
        {/* Protected routes */}
        <Route path="/" element={<Layout />} />
        <Route path="pnc" element={<UploadPNC />} />
        <Route path="otr" element={<UploadOTR />} />
        <Route path="gsm" element={<UploadGSM />} />
        {/* <Route path="data" element={<GridExample />} /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
