// Layout.jsx
import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Login from "./Login";
// import Dashboard from "./parts/Dashboard";
import Dashboard from "./Dashboard";
import HeaderNav from "../HeaderNav";

const Layout = () => {
  const navigate = useNavigate();
  const isLoggedIn = localStorage.getItem("isLoggedIn") === "true";

  const handleLogout = () => {
    localStorage.removeItem("isLoggedIn");
    navigate("/login");
  };

  return (
    <>
      {isLoggedIn && <HeaderNav handleLogout={handleLogout} />}
      <div className="wrapper">{isLoggedIn ? <Dashboard /> : <Login />}</div>
      <Outlet />
    </>
  );
};

export default Layout;
