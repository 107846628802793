import React from "react";
import { Modal } from "react-bootstrap";

const InfoOTR = (props) => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          File Upload Guidelines
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ul>
          <li>
            <p>
              Please ensure the file format is CSV. Uploading other formats may
              result in errors.
            </p>
          </li>
          <li>
            <p>
              Maximum file size allowed is 1MB. Larger files may take longer to
              upload.
            </p>
          </li>
          <li>
            <p>
              File name should follow this structure:{" "}
              <strong>FAO_ORDER_TO_TRADE_RATIO_{"{date}"}.csv</strong>, where{" "}
              <strong>{"{date}"}</strong> represents the last trading or relevant
              date associated with the data in <em>ddMMyyyy</em> format (e.g.,
              <strong> FAO_ORDER_TO_TRADE_RATIO_25062024.csv</strong>).
            </p>
          </li>
          <li>
            <p>
              Make sure the file content is accurate and relevant to the purpose
              of the upload.
            </p>
          </li>
          <li>
            <p>
              By clicking "Upload", you agree to abide by our file upload terms
              and conditions.
            </p>
          </li>
        </ul>
      </Modal.Body>
    </Modal>
  );
};

export default InfoOTR;
