import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

function DownloadButton({ rowData, fileNamePrefix, date }) {
  const convertToCSV = (objArray) => {
    if (!objArray || objArray.length === 0) {
      return ''; // Return an empty string for blank CSV
    }
    const array = [Object.keys(objArray[0])].concat(objArray);
    return array
      .map((row) => {
        return Object.values(row)
          .map((value) => (typeof value === 'string' ? value.replace(/"/g, '""') : value))
          .join(',');
      })
      .join('\n');
  };

  const downloadCSV = () => {
    let csvData = convertToCSV(rowData);
    if (!csvData) {
      csvData = 'No data available\n'; // Create a header for the blank CSV
    }
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${fileNamePrefix}_${date}.csv`;
    link.click();
    URL.revokeObjectURL(link.href);
  };

  return (
    <div>
      <button className="download-btn" onClick={downloadCSV}>
        <FontAwesomeIcon icon={faDownload} />
      </button>
    </div>
  );
}

export default DownloadButton;
